import * as ActionTypes from "../actionTypes";
import Flatten from "@flatten-js/core";

export const defaultMeasureShapesState = {
    measureShapesActive: true,
    measureShapesFirstClick: true,
    hoveredShape: null,
    firstMeasuredShape: null,
    secondMeasuredShape: null,
    firstMeasuredLayer: null,
    secondMeasuredLayer: null,
    distance: undefined,
    shortestSegment: null
};

const is_mobile = window.mobilecheck();

const measureShapesReducer = (state, action) => {
    switch (action.type) {
        // case ActionTypes.MEASURE_SHAPES_BUTTON_PRESSED:
        //     return {
        //         ...state,
        //         measureShapesActive: true
        //     };

        case ActionTypes.PAN_BY_DRAG_BUTTON_CLICKED:
            return defaultMeasureShapesState;

        case ActionTypes.MEASURE_POINTS_BUTTON_PRESSED:
            return defaultMeasureShapesState;

        case ActionTypes.MOUSE_ROLL_OVER_SHAPE:
            return {
                ...state,
                hoveredShape: state.measureShapesActive ? action.shape : null
            };
        case ActionTypes.MOUSE_ROLL_OUT_SHAPE:
            return {
                ...state,
                hoveredShape: null
            };
        case ActionTypes.MOUSE_CLICKED_ON_SHAPE:
            if (!state.measureShapesActive) {
                return state;
            }
            // measureShapesActive

            if (state.measureShapesFirstClick) {
                return {
                    ...state,
                    firstMeasuredShape: action.shape,
                    firstMeasuredLayer: action.layer,
                    secondMeasuredShape: null,
                    secondMeasuredLayer: null,
                    measureShapesFirstClick: false,
                    distance: undefined,
                    shortestSegment: null
                }
            }
            else {    // second click
                if (action.shape === state.firstMeasuredShape) {
                    return state;  // second click on the same shape
                }

                let shape1 = state.firstMeasuredShape.geom;
                let shape2 = action.shape.geom;
                let distance, shortestSegment;
                // if (shape1 instanceof Flatten.Polygon && shape2 instanceof Flatten.Polygon) {
                //     [distance, shortestSegment] = Flatten.Distance.polygon2polygon(shape1, shape2);
                // }
                // else {
                [distance, shortestSegment] = Flatten.Distance.distance(shape1, shape2);
                // }


                return {
                    ...state,
                    secondMeasuredShape: action.shape,
                    secondMeasuredLayer: action.layer,
                    measureShapesFirstClick: true,
                    distance: distance,
                    shortestSegment: shortestSegment
                };
            }
        case ActionTypes.MOUSE_DOWN_ON_STAGE:
            if (state.hoveredShape || is_mobile) {
                return state
            }
            else {
                return {
                    ...state,
                    measureShapesFirstClick: true,
                    firstMeasuredShape: null,
                    firstMeasuredLayer: null,
                    secondMeasuredShape: null,
                    secondMeasuredLayer: null,
                    distance: undefined,
                    shortestSegment: null
                };
            }

        case ActionTypes.DELETE_LAYER_BUTTON_PRESSED:
            // TODO: dispatch action on delete layer to come here
            if (action.layer === state.firstMeasuredLayer ||
                action.layer === state.secondMeasuredLayer) {
                return defaultMeasureShapesState;
            }
            else {
                return state;
            }

        case ActionTypes.TOGGLE_DISPLAY_LAYER_PRESSED:
            // TODO: dispatch action on delete layer to come here
            if (action.layer.displayed &&
                (action.layer === state.firstMeasuredLayer ||
                    action.layer === state.secondMeasuredLayer)) {
                return defaultMeasureShapesState;
            }
            else {
                return state;
            }

        default:
            return state;
    }
}

export default measureShapesReducer;
