/**
 * Created by alexanderbol on 17/04/2017.
 */
import Button from '../UI/Button/Button';
import Separator from '../UI/ButtonSeparator/ButtonSeparator';
import classes from './ToolbarComponent.module.css';
import '@fortawesome/fontawesome-free/css/all.css';
import * as appActions from "../../store/actions/appActions";
import * as layerActions from "../../store/actions/layersActions";

const ToolbarComponent = (props) => {
        // openJobButtonClicked = () => this.refs.browseFiles.click();

        return (
            <div className={classes["App-toolbar"]}>
                    {/*<h4>Toolbar</h4>*/}
                    <Button type="trigger" title="Open files" iconName='folder-open'
                            onClick={() => props.appStateDispatch(appActions.toggleUploadPopup())}
                    />

                    {/*<input style={{fontSize: 16, marginTop: 5, marginBottom: 5, display: "none"}}*/}
                    {/*type="file" id="browseFiles" ref="browseFiles" name="files[]" multiple*/}
                    {/*onChange={this.props.onFileSelected}*/}
                    {/*/>*/}

                    <Separator/>

                    <Button type="trigger" title="Show/hide layers" iconName='layer-group'
                            onClick={() => props.appStateDispatch(appActions.toggleLayersWidget())}
                    />

                    {/*<Button type="trigger" title="Save document on cloud" iconName='save'*/}
                    {/*onClick={this.props.onSaveDocumentButtonClicked}*/}
                    {/*/>*/}

                    <Button type="trigger" title="Sort layers names in the Layers Widget" iconName='sort-alpha-down'
                            onClick={() => props.layersDispatch(layerActions.sortLayers())}
                    />

                    <Button type="trigger" title="Download layers" iconName='file-download'
                            onClick={() => props.appStateDispatch(appActions.toggleDownloadPopup())}
                    />

                    <Button type="trigger" title="Delete all layers" iconName='broom'
                            onClick={() => props.layersDispatch(layerActions.deleteAllLayers())}
                    />

                    <Separator/>

                    <Button type="trigger" title="Zoom and pan to home view" iconName='home'
                            onClick={props.onHomeButtonPressed}
                    />

                    <Separator/>

                    <Button type="mode" title="Pan by drag" iconName='arrows-alt'
                            active={!props.measurePointsActive}
                            onClick={() => props.appStateDispatch(appActions.togglePanByDrag())}
                    />

                    <Button type="mode" title="Measure distance between points" iconName='drafting-compass'
                            active={props.measurePointsActive}
                            onClick={() => props.appStateDispatch(appActions.toggleMeasureBetweenPoints())}
                    />

                    <Separator/>

                    <Button type="trigger" title="Measure distance between selected shapes" iconName='ruler'
                            onClick={() => props.appStateDispatch(appActions.toggleMeasureBetweenShapes())}
                    />

                    <Button type="trigger" title="Display solid or wire" iconName='fill-drip'
                            onClick={() => props.appStateDispatch(appActions.toggleWidthMode())}
                    />

                    <Button type="trigger" title="Display vertices on/off" iconName='draw-polygon'
                            onClick={() => props.appStateDispatch(appActions.toggleDisplayVertices())}
                    />

                    <Button type="trigger" title="Display labels on/off" iconName='tag'
                            onClick={() => props.appStateDispatch(appActions.toggleDisplayLabels())}
                    />

                    <Separator/>

                    <button className={classes["App-toolbar-units"]}
                            onClick={props.onUnitClicked}
                    >
                            {props.units}
                    </button>

                    <Separator/>

                    <Button type="trigger" title="About" iconName='info'
                            onClick={() => props.appStateDispatch(appActions.toggleAboutPopup())}
                    />

                    <Separator/>

            </div>
        )
}

export default ToolbarComponent;
