/**
 * Created by alexanderbol on 13/04/2017.
 */
export const NEW_STAGE_CREATED = "NEW_STAGE_CREATED";
// export const STAGE_RESIZED = "STAGE_RESIZED";
export const NEW_SHAPE_PASTED = "NEW_SHAPE_PASTED";
export const CLEAR_ALL = "CLEAR_ALL";
export const STAGE_UNMOUNTED = "STAGE_UNMOUNTED";

/** Toolbar buttons **/
export const PAN_BY_DRAG_BUTTON_CLICKED = "PAN_BY_DRAG_BUTTON_CLICKED";
// export const SAVE_DOCUMENT_BUTTON_CLICKED = "SAVE_DOCUMENT_BUTTON_CLICKED";
export const MANAGE_CLOUD_STORAGE_BUTTON_CLICKED = "MANAGE_CLOUD_STORAGE_BUTTON_CLICKED";

export const MEASURE_POINTS_BUTTON_PRESSED = "MEASURE_POINTS_BUTTON_PRESSED";
// export const MEASURE_SHAPES_BUTTON_PRESSED = "MEASURE_SHAPES_BUTTON_PRESSED";
export const TOGGLE_ABOUT_POPUP_CLICKED = "TOGGLE_ABOUT_POPUP_CLICKED";
// export const CLOSE_ABOUT_POPUP_BUTTON_PRESSED = "CLOSE_ABOUT_POPUP_BUTTON_PRESSED";
export const TOGGLE_UNITS_CLICKED = "TOGGLE_UNITS_CLICKED";
export const TOGGLE_WIDTH_MODE_CLICKED = "TOGGLE_WIDTH_MODE_CLICKED";
export const TOGGLE_DISPLAY_VERTICES_CLICKED = "TOGGLE_DISPLAY_VERTICES_CLICKED";
export const TOGGLE_DISPLAY_LABELS_CLICKED = "TOGGLE_DISPLAY_LABELS_CLICKED";
export const TOGGLE_SHOW_LAYER_LIST_CLICKED = "TOGGLE_SHOW_LAYER_LIST_CLICKED";
export const TOGGLE_DOWNLOAD_POPUP_CLICKED = "TOGGLE_DOWNLOAD_POPUP_CLICKED";
export const TOGGLE_UPLOAD_POPUP_CLICKED="TOGGLE_UPLOAD_POPUP_CLICKED";

export const MEASURE_SHAPES_BUTTON_PRESSED = "MEASURE_SHAPES_BUTTON_PRESSED";

export const MOUSE_MOVED_ON_STAGE = "MOUSE_MOVED_ON_STAGE";
export const MOUSE_DOWN_ON_STAGE = "MOUSE_DOWN_ON_STAGE";
export const MOUSE_UP_ON_STAGE = "MOUSE_UP_ON_STAGE";
export const MOUSE_WHEEL_MOVE_ON_STAGE = "MOUSE_WHEEL_MOVE_ON_STAGE";

export const MOUSE_ROLL_OVER_SHAPE = "MOUSE_ROLL_OVER_SHAPE";
export const MOUSE_ROLL_OUT_SHAPE = "MOUSE_ROLL_OUT_SHAPE";
export const MOUSE_CLICKED_ON_SHAPE = "MOUSE_CLICKED_ON_SHAPE";

export const PINCH_DOWN_ON_STAGE = "PINCH_DOWN_ON_STAGE";
export const PINCH_MOVED_ON_STAGE = "PINCH_MOVED_ON_STAGE";
export const PINCH_UP_ON_STAGE = "PINCH_UP_ON_STAGE";

// export const STAGE_SET_ORIGIN = "STAGE_SET_ORIGIN";
// export const HOME_BUTTON_CLICKED = "HOME_BUTTON_CLICKED";
// export const UNSET_ZOOM_HOME_FLAG = "UNSET_ZOOM_HOME_FLAG";
// export const PAN_TO_COORDINATE = "PAN_TO_COORDINATE";
export const PAN_AND_ZOOM_TO_SHAPE = "PAN_AND_ZOOM_TO_SHAPE";

export const LAYER_LIST_PANEL_PRESSED = "LAYER_LIST_PANEL_PRESSED";
export const ADD_LAYER_BUTTON_PRESSED = "ADD_LAYER_BUTTON_PRESSED";
export const ADD_NEW_LAYER = "ADD_NEW_LAYER";
export const DELETE_LAYER_BUTTON_PRESSED = "DELETE_LAYER_BUTTON_PRESSED";
export const SORT_LAYERS_BUTTON_PRESSED = "SORT_LAYERS_BUTTON_PRESSED";
export const CLEAN_AND_UPDATE_LAYERS = "CLEAN_AND_UPDATE_LAYERS";
export const CLEAR_ALL_BUTTON_CLICKED = "CLEAR_ALL_BUTTON_CLICKED";
export const ADD_SHAPES_TO_NEW_LAYER = "ADD_SHAPES_TO_NEW_LAYER";

export const TOGGLE_DISPLAY_LAYER_PRESSED = "TOGGLE_DISPLAY_LAYER_PRESSED";
export const TOGGLE_AFFECTED_LAYER_PRESSED="TOGGLE_AFFECTED_LAYER_PRESSED";
export const OPEN_LAYER_EDIT_FORM_PRESSED = "OPEN_LAYER_EDIT_FORM_PRESSED";
export const SUBMIT_LAYER_EDIT_FORM_PRESSED = "SUBMIT_LAYER_EDIT_FORM_PRESSED";
export const ESCAPE_LAYER_EDIT_FORM_PRESSED = "ESCAPE_LAYER_EDIT_FORM_PRESSED";

export const LAYERS_LIST_ARROW_DOWN_PRESSED = "LAYERS_LIST_ARROW_DOWN_PRESSED";
export const LAYERS_LIST_ARROW_UP_PRESSED = "LAYERS_LIST_ARROW_UP_PRESSED";

// export const TOGGLE_WATCH_EXPAND_CLICKED = "TOGGLE_WATCH_EXPAND_CLICKED";

// export const FILENAME_LIST_SELECTED = "FILENAME_LIST_SELECTED";
export const FILES_UPLOAD_COMPLETED = "FILES_UPLOAD_COMPLETED";
export const INITIALIZE_UPLOAD_STATE = "INITIALIZE_UPLOAD_STATE";
export const DATA_FROM_BUFFER_PASTED = "DATA_FROM_BUFFER_PASTED";

/** AABB Distance Measurement DemoComponent **/
// export const AABB_DEMO_URI = "AABB_DEMO_URI";
// export const AABB_DEMO_NEXT_DIST_STEP = "AABB_DEMO_NEXT_DIST_STEP";
// export const AABB_TREE_NEXT_LEVEL = "AABB_TREE_NEXT_LEVEL";

/** Collision demo **/
// export const COLLISION_DEMO_URI = "COLLISION_DEMO_URI";
// export const COLLISION_DEMO_BUTTON_PRESSED = "COLLISION_DEMO_BUTTON_PRESSED";

export const ASYNC_OPERATION_STARTED = "ASYNC_OPERATION_STARTED";
export const ASYNC_OPERATION_ENDED = "ASYNC_OPERATION_ENDED";

/** Documents list **/
// export const REQUEST_ADD_DOCUMENT_TO_DATABASE_SUCCEED = "REQUEST_ADD_DOCUMENT_TO_DATABASE_SUCCEED";
// export const REQUEST_UPDATE_DOCUMENT_IN_DATABASE_SUCCEED = "REQUEST_UPDATE_DOCUMENT_IN_DATABASE_SUCCEED";
// export const REQUEST_FETCH_DOCUMENT_FROM_DATABASE_SUCCEED = "REQUEST_FETCH_DOCUMENT_FROM_DATABASE_SUCCEED";
// export const REQUEST_FETCH_DOCUMENTS_FROM_DATABASE_SUCCEED = "REQUEST_FETCH_DOCUMENTS_FROM_DATABASE_SUCCEED";
// export const DELETE_DOCUMENT_FROM_DATABASE_SUCCEED = "DELETE_DOCUMENT_FROM_DATABASE_SUCCEED";
// export const DOCUMENT_SELECTED_FROM_LIST = "DOCUMENT_SELECTED_FROM_LIST";
// export const DOCUMENT_NAME_UPDATED = "DOCUMENT_NAME_UPDATED";

/* Authentication */
// export const AUTHENTICATION_STARTED = "AUTHENTICATION_STARTED";
// export const AUTHENTICATION_SUCCEED = "AUTHENTICATION_SUCCEED";
// export const AUTHENTICATION_FAILED = "AUTHENTICATION_FAILED";
// export const AUTHENTICATION_LOGOUT = "AUTHENTICATION_LOGOUT";
// export const AUTHENTICATION_SET_USERNAME = "AUTENTICATION_SET_USERNAME";

