import {parseXML} from "./parserXML";
import {parseCSV} from "./parseCSV";
import {parseTXT} from "./parseTXT";
import {parseJSON} from "./parseJSON";
import {parseFlighta} from "./parseFlighta";
import {parseODB} from "./parserODB";
import {parseImage} from "./parseImage";
import parseKeyPoints from "./parseKeyPoints";
import parseCenterOfGeometry from "./parseCenterOfGeometry";

export const parse = (data) => {
    const filename = data.name
    const string = data.content
    const type = data.type
    let extension = filename.split('.').at(-1);   // caniuse instead of namesplitted[namesplitted.length-1]; ?
    let job;
    let shapes = [];
    if (extension === 'xml') {
        job = parseXML(filename, string);
        shapes = [...job.profiles, ...job.materials, ...job.shapes]
    }
    else if (extension === 'csv') {
        job = parseCSV(filename, string);
        shapes = job.shapes;
    }
    else if (extension === 'txt') {
        job = parseTXT(filename, string);
        shapes = job.shapes;
    }
    else if (extension === 'json') {
        if (filename.match("key_points")) {
            job = parseKeyPoints(filename, string)
        }
        else if (filename.match('center_of_geometry')) {
            job = parseCenterOfGeometry(filename, string)
        }
        else {
            job = parseJSON(filename, string);
        }
        shapes = job.shapes;
    }
    else if (extension === 'cdbg') {
        job = parseFlighta(filename, string);
        shapes = job.shapes;
    }
    else if (type === 'image') {
        const shapeImage = parseImage(string)
        shapes = [shapeImage]
    }
    else {
        let arrayOfLines = string.match(/[^\r\n]+/g);
        if (arrayOfLines[0].length === 1 && arrayOfLines[0] === "N") {
            job = parseFlighta(filename, string);
            shapes = job.shapes;
        }
        else {
            job = parseODB(filename, string);
            shapes = job.shapes;
        }
    }

    return shapes;
}
