import {useCallback, useEffect, useRef, useState} from 'react';
// import LayerListToolbar from "../../LayersList/LayerListToolbar/LayerListToolbar";
import LayerListElement from './LayerListElement/LayerListElement';
import styles from './LayersWidget.module.css';

const LayersWidget = (props) => {
    const widgetRef = useRef(null);
    const [height, setHeight] = useState(0)

    const handleKeyDown = useCallback((e) => {
        // e.stopPropagation();
        // e.preventDefault();

        if (e.target.parentElement.parentElement &&
            e.target.parentElement.parentElement.parentElement &&
            e.target.parentElement.parentElement.parentElement.id &&
            e.target.parentElement.parentElement.parentElement.id === "layersList") {


            switch (e.code) {
                case "ArrowRight":
                case "ArrowDown":
                    props.setAffectedNextLayer();
                    break;
                case "ArrowLeft":
                case "ArrowUp":
                    props.setAffectedPrevLayer();
                    break;
                default:
                    break;
            }
        }
    },[props]);

    useEffect( () => {
        setHeight(widgetRef.current.clientHeight)
    },[]);

    useEffect( () => {
        document.addEventListener('keydown', handleKeyDown);
    })

    return (
        <div className={styles["App-layers-displayed"]}
             ref={widgetRef}
             // onClick={props.onLayerListClicked}
        >
            {/*<h5>Layers</h5>*/}
            {/*<LayerListToolbar*/}
            {/*    // onAddLayerButtonClicked={() => this.props.onAddLayerButtonClicked(this.props.stage)}*/}
            {/*    onEditLayerButtonClicked={props.onEditLayerButtonClicked}*/}
            {/*    onDeleteLayerButtonClicked={props.onDeleteLayerButtonClicked}*/}
            {/*/>*/}
            <ul id="layersList"
                style={{maxHeight:0.82*(height-40)}}>
                { props.layers.map((layer, index) =>
                    <LayerListElement
                        key={index}
                        layer={layer}
                        onLayerClicked={() => props.toggleDisplayLayer(layer)}
                        onAffectedBoxClicked={(event) => props.toggleAffectedLayer(event, layer)}
                        onEditLayerButtonClicked={props.onEditLayerButtonClicked}
                        onDeleteLayerButtonClicked={props.onDeleteLayerButtonClicked}
                        onSubmitLayerEditForm={props.onSubmitLayerEditForm}
                        onEscapeLayerEditForm={props.onEscapeLayerEditForm}
                    />)
                }
            </ul>
            {/*{addLayer}*/}
        </div>
    )
}

export default LayersWidget;
