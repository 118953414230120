import {Fragment} from 'react';
import LayerComponent from "./layerComponent";
import LabelsLayerComponent from "./LabelsLayerComponent/labelsLayerComponent";
import VerticesLayerComponent from './verticesLayerComponent';

const LayersComponent = (props) => {
    return props.layers.map((layer, index) =>
        <Fragment key={index}>
            <LayerComponent
                stage={props.stage}
                layer={layer}
                widthOn={props.widthOn}
                displayVertices={props.displayVertices}
                zoomFactor={props.zoomFactor}
                resolution={props.resolution}
                hoveredShape={props.hoveredShape}
                firstMeasuredShape={props.firstMeasuredShape}
                secondMeasuredShape={props.secondMeasuredShape}
                measureShapesDispatch={props.measureShapesDispatch}
            />

            <LabelsLayerComponent
                stage={props.stage}
                layer={layer}
                displayLabels={props.displayLabels}
                zoomFactor={props.zoomFactor}
                originX={props.originX}
                originY={props.originY}
            />

            <VerticesLayerComponent
                stage={props.stage}
                layer={layer}
                displayVertices={props.displayVertices}
                divisor={props.divisor}
                decimals={props.decimals}
                zoomFactor={props.zoomFactor}
                originX={props.originX}
                originY={props.originY}
            />
        </Fragment>
    )
}

export default LayersComponent
