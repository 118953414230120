import ShapeComponent from './shapeComponent';
import ImageComponent from "./imageComponent";

const LayerComponent = (props) => {
    return (
        props.layer.shapes.map((shape, index) => {
            const hovered = shape === props.hoveredShape;
            let selected = shape === props.firstMeasuredShape || shape === props.secondMeasuredShape;

                return shape.geom.img ? (
                    <ImageComponent
                        key={index}
                        stage={props.stage}
                        layer={props.layer}
                        model={shape}
                        displayed={props.layer.displayed}
                        color={props.layer.color}
                        zoomFactor={props.zoomFactor}
                        // onMouseOver={props.onMouseOver}
                        // onMouseOut={props.onMouseOut}
                        // onClick={props.onClick}
                    />
                ) : (
                    <ShapeComponent
                        key={index}
                        stage={props.stage}
                        layer={props.layer}
                        model={shape}
                        displayed={props.layer.displayed}
                        color={props.layer.color}
                        widthOn={props.widthOn}
                        zoomFactor={props.zoomFactor}
                        resolution={props.resolution}
                        hovered={hovered}
                        selected={selected}
                        measureShapesDispatch={props.measureShapesDispatch}
                    />)
            }
        )
    )
}

export default LayerComponent;
