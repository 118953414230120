import {Box} from '@flatten-js/core';

// const DEFAULT_IMAGE_WIDTH = 1048
// const DEFAULT_IMAGE_HEIGHT = 1048

export function parseImage(img) {
    let imageShape = {};
    imageShape.img = img;
    imageShape.box = new Box(0, 0, img.width, img.height)
    return imageShape
}
