/**
 * Created by alexanderbol on 21/04/2017.
 */
import {format} from '../../models/utils';
import styles from './DisplayCoordinatesTool.module.css';

const DisplayCoordinatesTool = ({stage, coordX, coordY, divisor, decimals}) => {
    if (!stage) return null;
    let mainCanvas = stage.canvas;
    let top = mainCanvas.offsetTop + 10;
    let left = mainCanvas.width - 200; //  mainCanvas.offsetLeft + 10;
    let x = stage.C2W_X(coordX);
    let y = stage.C2W_Y(coordY);
    return (
        <div style={{position: 'absolute', top: top, left: left}} className={styles.DisplayCoordinatesTool}>
            <h5>
                X: {format(x,divisor,decimals)} Y: {format(y,divisor,decimals)}
            </h5>
        </div>
    )
};

export default DisplayCoordinatesTool;
