import {useCallback, useEffect, useReducer} from "react";
import mouse, {defaultMouseState} from "../store/reducers/mouse";
import * as stageActions from "../store/actions/stageActions";
import * as measureShapesActions from "../store/actions/measureShapesActions";

const useMouse = (stage, measureShapesDispatch) => {
    const [mouseState, mouseDispatch] = useReducer(mouse, defaultMouseState);

    const getTouchPoints = (event) => {
        const touchPoints = [
            {
                x: event.nativeEvent.touches[0].clientX - event.target.offsetLeft,
                y: event.nativeEvent.touches[0].clientY - event.target.offsetTop
            },
            {
                x: event.nativeEvent.touches[1].clientX - event.target.offsetLeft,
                y: event.nativeEvent.touches[1].clientY - event.target.offsetTop
            },
        ];
        return touchPoints;
    }

    const handleMouseMove = useCallback( (event) => {
        stage.canvas.focus();
        if (!event.nativeEvent.touches || event.nativeEvent.touches.length === 1) {
             mouseDispatch(stageActions.handleMouseMove(stage, event.stageX, event.stageY));
        }
        else if (event.nativeEvent.touches && event.nativeEvent.touches.length === 2) {
            const touchPoints = getTouchPoints(event);
            mouseDispatch(stageActions.handlePinchMove(stage, touchPoints));
        }
    }, [stage])

    const handleMouseDown = useCallback( (event) => {
        // if (event.pointerID === -1 || event.pointerID === 0) {
        if (!event.nativeEvent.touches || event.nativeEvent.touches.length === 1) {
            stage.panByMouseStart();
            mouseDispatch(stageActions.handleMouseDown(stage, event.stageX, event.stageY));
            measureShapesDispatch(measureShapesActions.handleMouseDown());
        }
        else if (event.touches && event.touches.length === 2) {
            const touchPoints = getTouchPoints(event);
            stage.zoomByPinchStart(touchPoints[0].x, touchPoints[0].y);
            mouseDispatch(stageActions.handlePinchDown(stage, touchPoints));
        }
    }, [stage, measureShapesDispatch]);

    const handleMouseUp = useCallback( (event) => {
        event.stopPropagation();
        event.preventDefault();
        if (!event.nativeEvent.touches || event.nativeEvent.touches.length === 1) {
            stage.panByMouseStop();
            mouseDispatch(stageActions.handleMouseUp(stage));
        }
        else if (event.nativeEvent.touches && event.nativeEvent.touches.length === 2) {
            stage.zoomByPinchStop();
            mouseDispatch(stageActions.handlePinchUp(stage));
        }
    }, [stage]);

    const handleMouseLeave = useCallback( (event) => {   // nothing works except click
        stage.canvas.blur();
        document.body.focus();
    }, [stage]);

    const handleMouseWheel = useCallback((event) => {
        let delta = event.detail || event.wheelDelta;
        // if (delta !== 0) {
            mouseDispatch(stageActions.handleMouseWheelMove(stage, event.offsetX, event.offsetY, delta));
        // }
    },[stage]);

    const handleMouseWheelFox = useCallback((event) => {
        /*event.preventDefault();*/
        // if (event.detail !== 0) {
            mouseDispatch(stageActions.handleMouseWheelMove(stage, event.layerX, event.layerY, -event.detail));
        // }
    },[stage]);

    // useEffect( () => {
    //     if (canvasElement) {
    //         const newStage = new Stage(canvasElement);
    //         setStage(newStage);
    //     }
    // },[canvasElement])

    useEffect( () => {
        if (stage) {
            stage.on("stagemousedown", handleMouseDown);
            stage.on("stagemousemove", handleMouseMove);
            stage.on("stagemouseup", handleMouseUp);
            stage.on("mouseleave", handleMouseLeave);
            stage.canvas.addEventListener("mousewheel", handleMouseWheel, {passive: true});
            stage.canvas.addEventListener("DOMMouseScroll", handleMouseWheelFox, {passive: true});

            return () => {
                stage.off("stagemousedown", handleMouseDown);
                stage.off("stagemousemove", handleMouseMove);
                stage.off("stagemouseup", handleMouseUp);
                stage.off("mouseleave", handleMouseLeave);
                stage.canvas.removeEventListener("mousewheel", handleMouseWheel);
                stage.canvas.removeEventListener("DOMMouseScroll", handleMouseWheelFox);
            }
        }
    },[stage, handleMouseDown, handleMouseMove, handleMouseUp, handleMouseLeave,
        handleMouseWheel, handleMouseWheelFox]);

    // console.log(mouseState)

    return mouseState;
}

export default useMouse;
