import {Fragment, useEffect, useReducer, useRef, useState} from 'react';
import styles from './HomeComponent.module.css';
import EditorComponent from "../EditorComponent/EditorComponent";
import {parseJSON} from "../../dataParsers/parseJSON";
import {parseDebug} from "../../dataParsers/parseDebug";

import units, {initialUnitsState} from "../../store/reducers/units";
import app, {defaultAppState} from "../../store/reducers/app";
import upload, {defaultUploadState} from "../../store/reducers/upload";
import layersReducer from "../../store/reducers/layersReducer";

import * as appActions from "../../store/actions/appActions";
import * as uploadActions from "../../store/actions/upload";
import * as layersActions from "../../store/actions/layersActions";

import * as actionTypes from "../../store/actionTypes";
import ToolbarComponent from "../ToolbarComponent/ToolbarComponent";
import AboutPopup from "../AboutPopup/AboutPopup";
import DownloadPopup from "../DownloadPopup/DownloadPopup";
import UploadPopup from "../UploadPopup/UploadPopup";
import {readFile} from "../../models/readFile";
import {parse} from "../../dataParsers/parseAll";
import {parseTXT} from "../../dataParsers/parseTXT";
import Spinner from "../UI/Spinner/Spinner";
import { isWktString, parseWKT} from "@flatten-js/core";

// import AsideComponent from "../../components/Layout/AsideComponent/AsideComponent";

let demoURL = "https://gist.githubusercontent.com/alexbol99/e570fe783be88b556f4ca4b332da73f7/raw/741c87a7ceccdeadd9fbbac05bdfda22ebe94927/poly234.txt";

const HomeComponent = () => {
    const editorRef = useRef(null);
    const [unitsState, unitsDispatch] = useReducer(units, initialUnitsState);
    const [appState, appStateDispatch] = useReducer(app, defaultAppState);
    // eslint-disable-next-line
    const [uploadState, uploadDispatch] = useReducer(upload, defaultUploadState);
    const [layers, layersDispatch] = useReducer(layersReducer, [])
    const [showSpinner, setShowSpinner] = useState(false);

    const pasteDataFromBuffer = (event) => {
        setShowSpinner(true);
        for (let item of event.clipboardData.items) {
            if (item.type !== "text/plain")
                continue
            item.getAsString((string) => {
                let shapes = [];
                try {
                    let job = parseJSON("",string);
                    shapes = job.shapes
                } catch (e) {
                    console.log(e.message)
                    if (isWktString(string)) {
                        const res = parseWKT(string)
                        shapes = res instanceof Array ? res : [res ];
                    }
                    else {
                        shapes = parseDebug(string)
                    }
                }

                if (shapes.length > 0) {
                    layersDispatch(layersActions.addShapesToNewLayer(shapes, ""));
                }
            });
        }
        setShowSpinner(false);
    }

    const filesSelectedHandler = async (files) => {
        if (!(File && FileReader && FileList)) return;
        setShowSpinner(true);
        let promises = Array.from(files).map(file => readFile(file));
        let res = await Promise.allSettled(promises);
        let fulfilledPromises = res.filter(p => p.status === "fulfilled");
        let filesData = fulfilledPromises.map(p => p.value);
        for (const data of filesData) {
            let shapes = parse(data);
            if (shapes.length > 0) {
                layersDispatch(layersActions.addShapesToNewLayer(shapes, data.name));
            }
        }
        setHomeView()
        setShowSpinner(false)
    }

    const setHomeView = () => {
        editorRef.current.zoomHome();
    }

    useEffect( () => {
        const fetchDemo = async () => {
            const resp = await fetch(demoURL);
            const data = await resp.text();
            const job = parseTXT("layer"/*layer.name*/, data);
            const shapes = job.shapes;
            if (shapes.length > 0) {
                layersDispatch(layersActions.addShapesToNewLayer(shapes, ""));
            }
            setShowSpinner(false)
        }

        if (window.location.pathname.search('demo') >= 0) {
            setShowSpinner(true);
            fetchDemo()
                .catch(console.error)
        }
    }, [])

    return (
        <Fragment>
            <ToolbarComponent
                measurePointsActive={appState.measurePointsActive}
                units={unitsState.name}
                appStateDispatch={appStateDispatch}
                layersDispatch={layersDispatch}
                onHomeButtonPressed={setHomeView}
                onUnitClicked={() => unitsDispatch({type: actionTypes.TOGGLE_UNITS_CLICKED})}
            />

            <EditorComponent
                ref={editorRef}
                className={styles.HomeComponent}
                layers={layers}
                showLayersWidget={appState.showLayersWidget}
                widthOn={appState.widthOn}
                displayVertices={appState.displayVertices}
                displayLabels={appState.displayLabels}
                measurePointsActive={appState.measurePointsActive}
                measureShapesActive={appState.measureShapesActive}
                unitsName={unitsState.name}
                divisor={unitsState.divisor}
                decimals={unitsState.decimals}
                appState={appState}
                layersDispatch={layersDispatch}
                onPasteDataFromBuffer={pasteDataFromBuffer}
            />

            {appState.showAboutPopup && <AboutPopup
                showAboutPopup={appState.showAboutPopup}
                toggleAboutPopup={() => appStateDispatch(appActions.toggleAboutPopup())}
                title={appState.title}
                version={appState.version}
                build={appState.build}
            />}

            {appState.showDownloadPopup && <DownloadPopup
                showPopup={appState.showDownloadPopup}
                closePopup={() => appStateDispatch(appActions.toggleDownloadPopup())}
                title="Download document"
                layers={layers}
            />}

            {appState.showUploadPopup &&
                <UploadPopup
                    showPopup={appState.showUploadPopup}
                    closePopup={() => appStateDispatch(appActions.toggleUploadPopup())}
                    title="Upload files"
                    initUploadState={() => uploadDispatch(uploadActions.initUploadState())}
                    onFilesSelected={filesSelectedHandler}
                />}

            {showSpinner && <Spinner/>}

        </Fragment>
    );
};

export default HomeComponent;
