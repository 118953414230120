/**
 * Created by alexanderbol on 19/06/2017.
 */
import VertexComponent from './vertexComponent';
import Flatten from "@flatten-js/core";

const countEdgeIndex = (edge) => {
    let EdgeIndex = 0
    for (let cur_edge of edge.face) {
        if (cur_edge === edge)
            break
        EdgeIndex++;
    }
    return EdgeIndex
}

const VerticesComponent = (props) => {
    if (props.model.geom instanceof Flatten.Polygon) {
        return [...props.model.geom.edges].map( (edge, index) =>
            <VertexComponent key={index}
                             stage={props.stage}
                             vertex={edge.start}
                             vertexIndex={countEdgeIndex(edge)}
                             color={props.color}
                             divisor={props.divisor}
                             decimals={props.decimals}
                             zoomFactor={props.zoomFactor}
                             originX={props.originX}
                             originY={props.originY}
            />
        )
    }
    else {
        return props.model.geom.vertices ?
            props.model.geom.vertices.map((vertex, index) =>
                <VertexComponent key={index}
                                 stage={props.stage}
                                 vertex={vertex}
                                 vertexIndex={index}
                                 color={props.color}
                                 divisor={props.divisor}
                                 decimals={props.decimals}
                                 zoomFactor={props.zoomFactor}
                                 originX={props.originX}
                                 originY={props.originY}
                />
            ) : null;
    }

}

export default VerticesComponent;