import classes from './LabelsLayerComponent/labelsLayer.module.css';
import Layers from "../../models/layers";

const labelComponent = ( {stage, shape} ) => {
    let geom = shape.geom.clone();
    let matrix = Layers.getMatrix();
    if (geom.transform) {
        geom = geom.transform(matrix)
    }
    let center = geom.box.center;
    let left = stage.canvas.offsetLeft + stage.W2C_X(center.x) + 6;
    let top = stage.canvas.offsetTop + stage.W2C_Y(center.y) - 4;
    let inside = left > stage.canvas.offsetLeft && left < stage.canvas.offsetLeft + stage.canvas.width &&
        top > stage.canvas.offsetTop && top < stage.canvas.offsetTop + stage.canvas.height;

    return inside ? (
        <div style={{position:"absolute", left:left+"px", top: top+"px"}} className={classes.LabelComponent}>
            <h5>{shape.label}</h5>
        </div>
    ) : null;
};

export default labelComponent;
