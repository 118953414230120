import {useEffect} from 'react';
import './App.css';
import styles from "./App.module.css";
import HeaderComponent from './components/Layout/HeaderComponent/HeaderComponent';
import HomeComponent from "./components/HomeComponent/HomeComponent";

// import AsideComponent from "./components/Layout/AsideComponent/AsideComponent";
// import StatusComponent from "./components/Layout/StatusComponent/StatusComponent";


const title = "Debug Viewer";
const version = process.env.REACT_APP_VERSION;

const App = (props) => {
    useEffect( () => {
        window.addEventListener('beforeunload', function (e) {
            e.preventDefault();
            e.returnValue = '';
        });
    },[]);

    return (
        <div className={styles.App}>
            <HeaderComponent
                title={title}
                version={version}
            />

            <HomeComponent />

            {/*<StatusComponent />*/}
        </div>
    );
}

export default App;
