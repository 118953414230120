import {useState, useEffect} from 'react';
import {Shape, Text} from '@createjs/easeljs';
import {graphics} from '../models/graphics';

const MeasureShapesTool = ({stage, transform,
                               firstMeasuredShape, secondMeasuredShape, firstMeasuredLayer, secondMeasuredLayer,
                               distance, shortestSegment, divisor, decimals
                           }) => {
    const [segment, setSegment] = useState(null);
    const [labelShape, setLabelShape] = useState(null);

    useEffect( () => {
        if (stage) {
            const newSegment = new Shape();
            stage.addChild(newSegment);
            setSegment(newSegment);


            const newLabelShape = new Text();
            newLabelShape.x = 0;
            newLabelShape.y = 0;
            stage.addChild(newLabelShape);
            setLabelShape(newLabelShape);
        }
    }, [stage])

    useEffect( () => {
        return () => {
            if (stage && segment && labelShape) {
                stage.removeChild(segment);
                stage.removeChild(labelShape);
                segment.graphics.clear();
                labelShape.text = "";
            }
        }
    }, [stage, segment, labelShape])

    useEffect( () => {
        const format = (num) => { return (num/divisor).toFixed(decimals); }

        if (stage && segment && labelShape) {
            segment.graphics = graphics(shortestSegment);

            let box = shortestSegment.box;
            let point = {x: (box.xmin + box.xmax) / 2, y: (box.ymin + box.ymax) / 2};
            let dx = 6. / (transform.zoomFactor * transform.resolution);
            let dy = 4. / (transform.zoomFactor * transform.resolution);

            if (box.ymin === box.ymax) {
                dx = 0;
                dy = -dy;
            }

            labelShape.text = format(shortestSegment.length);

            let fontSize = 14.;
            labelShape.font = `${fontSize}px Arial`;

            let unscale = 1. / (transform.zoomFactor * transform.resolution);
            // let tx = stage.canvas.offsetLeft / (stage.zoomFactor * stage.resolution) + point.x + dx;
            // let ty = -stage.canvas.offsetTop / (stage.zoomFactor * stage.resolution) + point.y + dy;
            let tx = point.x + dx;
            let ty = point.y + dy;
            labelShape.setTransform(tx, ty, unscale, -unscale);
            stage.update();
        }
    }, [stage, transform,
        labelShape, segment, firstMeasuredShape, secondMeasuredShape, firstMeasuredLayer.displayed, secondMeasuredLayer.displayed,
        distance, shortestSegment, divisor, decimals ])

    return null;
};

export default MeasureShapesTool;
