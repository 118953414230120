import VerticesComponent from './verticesComponent';

const verticesLayerComponent = (props) => {
    return (props.layer.displayed && props.displayVertices) ? (
        props.layer.shapes.map((shape, index) =>
            <VerticesComponent
                key={index}
                stage={props.stage}
                model={shape}
                color={props.layer.color}
                divisor={props.divisor}
                decimals={props.decimals}
                zoomFactor={props.zoomFactor}
                originX={props.originX}
                originY={props.originY}
            />
        )
    ) : null;
};

export default verticesLayerComponent;
