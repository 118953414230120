export const createImageElement = (dataUrl) => {
    return new Promise((resolve, reject) => {
        if (!dataUrl) {
            reject("Not a valid image");
        }
        const img = new Image();
        img.onload = () => {
            resolve(img);
        }
        img.crossOrigin = "Anonymous";
        img.src = dataUrl;
    });
}
