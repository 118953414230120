import {createImageElement} from "./imageElement";

export const readFile = (file) => {
    if (!(File && FileReader && FileList)) return;

    const reader = new FileReader();

    let string = ""
    let contentType = "text"
    // if (file.type !== "") {
    //     if (file.type.match('text.*') || file.type.match('application.*') || file.name.match('features*') || file.name.includes('.cdbg')) {
    //         contentType = "text"
    //     } else if (file.type.match('image.*')) {
    //         contentType = "image"
    //     }
    // }
    if (file.type.match('image.*')) {
        contentType = "image"
    }

    return new Promise( (resolve, reject) => {
        reader.onload = async (event) => {
            string = event.target.result;
            if (string && string.length > 0) {
                if (contentType === "image") {
                    const img = await createImageElement(string)
                    resolve({name: file.name, type: contentType, content: img});
                }
                else {
                    resolve({name: file.name, type: contentType, content: string});
                }
            }
            else {
                reject(`No data read from file ${file.name}`)
            }
        }

        if (contentType === "text") {
            reader.readAsText(file);
        } else if (contentType === "image") {
            reader.readAsDataURL(file)
        }
    });

};

