/**
 * Created by alexanderbol on 20/04/2017.
 */

import Layer from '../models/layer';
import {Matrix} from "@flatten-js/core";

const colors = [
    "#FF0303",
    "#4AA403",
    "#540080",
    /*"#FFFF40", Please, don't use yellow color. #22 */
    "#FF0080",
    "#AED424",
    "#545400",
    "#FFA980",
    "#A95480",
    "#A9A9FF",
    "#00FF00",
    /*"#FFEE76", Please, don't use yellow color. #22 */
    "#A40576",
    "#4385C8",
    "#1CC826",
    "#BDBD00",
    "#48316E",
    "#14616E",
    "#8040BD",
    "#DC7676",
    "#924845",
    "#A90000",
    "#FFA900"
];

class Layers {
    static matrix = new Matrix();

    static newLayer(stage, layers) {
        let layer = new Layer(stage);
        layer.name = Layers.getNewName(layers);
        if (layers.length === 0) { // first layer
            // layer.color = Layers.getNextColor(layersReducer);
            layer.displayed = false;
            layer.affected = false;
        }
        return layer;
    }

    static get defaultName() {
        return "layer";
    }

    static getNewName(layers) {
        let name = Layers.defaultName;
        let inc = 1;
        let comparator = (layer) => layer.name === name;
        while (layers.find(comparator) ) {
            name = Layers.defaultName + inc;
            inc++;
        }
        return name;
    }

    static getAffected(layers) {
        return layers.find((lay) => lay.affected);
    }

    static setAffected(layers, layer) {
        let currentAffectedLayer = Layers.getAffected(layers);
        if (currentAffectedLayer) {
            currentAffectedLayer.affected = false;
        }
        layer.affected = true;
    }

    static getNextColor(layers) {
        // Create map of used colors
        if (layers.length === 0)
            return colors[0]

        let m = new Map();          // <key:color, value: counter>
        // If layer displayed, count usages of each color, otherwise set counter to 0
        layers.forEach(layer =>
            m.set(layer.color,
                layer.displayed ? (m.has(layer.color) ? m.get(layer.color) + 1 : 1) : 0))

        let maxCounter = Math.max(...m.values());
        let color;
        for (let cnt=0; cnt < maxCounter; cnt++) {
            color = colors.find( col => !m.has(col) || m.get(col) === cnt );
            if (color)
                break;
        }
        if (color === undefined)
            color = colors[0];

        // for (let col of colors) {
        //     if (!layers.find((layer) =>
        //         layer.displayed && layer.color === col)) {
        //         color = col;
        //         break;
        //     }
        // }
        return color;
    }

    // static delete(layers, layer) {
    //     let index = layers.findIndex((l) => l === layer);
    //     let newLayers = layers.filter(l => l !== layer);
    //     if (index > 0) {
    //         newLayers[index-1].affected = true;
    //         if (newLayers[index-1].color === "") {
    //             newLayers[index - 1].color = Layers.getNextColor(newLayers);
    //         }
    //         newLayers[index-1].displayed = true;
    //     }
    //     return newLayers;
    // }

    static sort(layers) {
        let newLayers = layers.slice();
        newLayers.sort( function(l1, l2) {
            let name1 = l1.name.toUpperCase();
            let name2 = l2.name.toUpperCase();
            if (name1 < name2) {
                return -1;
            }
            if (name1 > name2) {
                return 1;
            }
            return 0;
        });
        return newLayers;
    }

    static toJSON(layers) {
        return layers.map( layer => layer.toJSON() );
    }

    static setMatrix(a,b,c,d,tx,ty) {
        Layers.matrix = new Matrix(a,b,c,d,tx,ty)
    }

    static getMatrix() {
        return Layers.matrix;
    }
}

export default Layers;
