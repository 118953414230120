import {Fragment} from 'react';
import ModalPopup from "../../UI/ModalPopup/ModalPopup";
import LayerEditForm from "../LayerEditForm/LayerEditForm";
import styles from './LayerListElement.module.css';
import FontAwesome from "react-fontawesome";

const LayerListElement = (props) => {

    const deleteLayerButtonHandler = (event) => {
        event.stopPropagation();
        props.onDeleteLayerButtonClicked(props.layer);
    }

    const editLayerButtonHandler = (event) => {
        event.stopPropagation();
        props.onEditLayerButtonClicked(props.layer);
    }

    const rgba = (hex, opacity) => {
        let r, g, b, percent;
        if (hex) {
            hex = hex.replace('#', '');
            r = parseInt(hex.substring(0, 2), 16);
            g = parseInt(hex.substring(2, 4), 16);
            b = parseInt(hex.substring(4, 6), 16);
            percent = opacity;
        }
        else {
            r = 147;
            g = 128;
            b = 108;
            percent = 0;
        }
        let result = `rgba(${r},${g},${b},${percent / 100})`;

        return result;
    }

    let displayed = props.layer.displayed ? "Layer-displayed" : "Layer-undisplayed";
    let color = displayed ? rgba(props.layer.color, 100) : rgba();
    let bgcolor = displayed ? rgba(props.layer.color, 30) : rgba();
    let alpha = props.layer.affected ? 1 : 0;
    let layerNameOpacity = props.layer.shapes.length === 0 ? 0.6 : 1.0;

    return <Fragment>
        <li
            className={styles.Layer}
            onClick={props.onLayerClicked}
            // onDoubleClick={this.props.onLayerDoubleClicked}
        >

            <div className={styles.LayerSelectionBox}
                 onClick={props.onAffectedBoxClicked}
            >
                <h4 className={styles.LayerSelectionMark}
                    style={{opacity: alpha, color: color}}>
                    ✓
                </h4>
            </div>

            <div className={styles.LayerNameBox}
                 style={{backgroundColor: bgcolor, opacity: layerNameOpacity}}>
                <h4 title={props.layer.title} tabIndex='1'>
                    {props.layer.name}
                </h4>
            </div>

            <button title="Edit layer properties" onClick={editLayerButtonHandler}>
                <FontAwesome name='pencil-alt' />
            </button>
            <button title="Delete layer" onClick={deleteLayerButtonHandler}>
                <FontAwesome name='trash-alt' />
            </button>
        </li>
        <ModalPopup
            showPopup={props.layer.edited}
            closePopup={props.onEscapeLayerEditForm}
            header="Layer properties"
        >
            <LayerEditForm
                layer={props.layer}
                onSubmitLayerEditForm={props.onSubmitLayerEditForm}
                onEscapeLayerEditForm={props.onEscapeLayerEditForm}
            />
        </ModalPopup>

    </Fragment>
}

export default LayerListElement;
