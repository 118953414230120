import * as ActionTypes from '../actionTypes';

export const destroyStage = () => {
    return {
        type: ActionTypes.STAGE_UNMOUNTED
    }
};

export const toggleUnits = () => {
    return {
        type: ActionTypes.TOGGLE_UNITS_CLICKED
    }
};

export const toggleWidthMode = () => {
    return {
        type: ActionTypes.TOGGLE_WIDTH_MODE_CLICKED
    }
};

export const toggleDisplayVertices = () => {
    return {
        type: ActionTypes.TOGGLE_DISPLAY_VERTICES_CLICKED
    }
};

export const toggleDisplayLabels = () => {
    return {
        type: ActionTypes.TOGGLE_DISPLAY_LABELS_CLICKED
    }
};

export const toggleAboutPopup = () => {
    return {
        type: ActionTypes.TOGGLE_ABOUT_POPUP_CLICKED
    }
};

export const toggleMeasureBetweenPoints = () => {
    return {
        type: ActionTypes.MEASURE_POINTS_BUTTON_PRESSED
    }
};

export const toggleMeasureBetweenShapes = () => {
    return {
        type: ActionTypes.MEASURE_SHAPES_BUTTON_PRESSED
    }
};

export const setHomeView = (stage, shape) => {
    return {
        type: ActionTypes.PAN_AND_ZOOM_TO_SHAPE,
        stage,
        shape
    }
};

export const togglePanByDrag = () => {
    return {
        type: ActionTypes.PAN_BY_DRAG_BUTTON_CLICKED
    }
};

export const asyncOperationStarted = () => {
    return {
        type: ActionTypes.ASYNC_OPERATION_STARTED
    }
};

export const asyncOperationEnded = () => {
    return {
        type: ActionTypes.ASYNC_OPERATION_ENDED
    }
};

export const openDocumentOnCloud = () => {
    return {
        type: ActionTypes.MANAGE_CLOUD_STORAGE_BUTTON_CLICKED
    }
};

export const toggleLayersWidget = () => {
    return {
        type: ActionTypes.TOGGLE_SHOW_LAYER_LIST_CLICKED
    }
};

export const toggleDownloadPopup = () => {
    return {
        type: ActionTypes.TOGGLE_DOWNLOAD_POPUP_CLICKED
    }
};

export const toggleUploadPopup = () => {
    return {
        type: ActionTypes.TOGGLE_UPLOAD_POPUP_CLICKED
    }
};

export const pasteDataFromBuffer = (clipboardData) => {
    return {
        type: ActionTypes.DATA_FROM_BUFFER_PASTED,
        data: clipboardData
    }
};


