import LabelComponent from '../labelComponent';

const labelsLayerComponent = (props) => {
    return (props.layer.displayed && props.displayLabels) ? (
        props.layer.shapes.map((shape, index) => {
                return (shape.label && shape.label.trim() !== "") ? (
                    <LabelComponent
                        key={index}
                        stage={props.stage}
                        shape={shape}
                        zoomFactor={props.zoomFactor}
                        originX={props.originX}
                        originY={props.originY}
                    />) : null;
            }
        )
    ) : null;
};

export default labelsLayerComponent;
