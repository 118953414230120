/**
 * Created by alexanderbol on 13/04/2017.
 */
import classes from './AboutPopup.module.css';
import ModalPopup from "../UI/ModalPopup/ModalPopup";

const AboutPopup = (props) => {
    return (
        <ModalPopup
            showPopup={props.showAboutPopup}
            closePopup={props.toggleAboutPopup}
            header="Info"
        >
            <div className={classes.AboutPopup}>
                <h2>{props.title} v{props.version}</h2>

                {/*<label>{`Build date: `}</label>*/}
                {/*<span>02/10/2021</span>*/}
                <br/>

                <label>{`Report issues: `}</label>
                <a href="https://github.com/alexbol99/debug-viewer1/issues" target="_blank" rel="noreferrer" >
                    https://github.com/alexbol99/debug-viewer1/issues
                </a>
                <br/>

                {/*<label>{`3d party graphics library: `}</label>*/}
                {/*<a href="https://github.com/CreateJS/EaselJS/tree/2.0" >*/}
                {/*    https://github.com/CreateJS/EaselJS/tree/2.0*/}
                {/*</a>*/}
                {/*<br/>*/}

            </div>
        </ModalPopup>

    )
};

export default AboutPopup;
