import {Fragment, useEffect} from 'react';

const StageComponent = ({children, stage, layers, transform, controls, hoveredShape, firstMeasuredShape, secondMeasuredShape}) => {

    useEffect( () => {
        if (stage.canvas && stage.canvas.getContext /*('2d')*/) {
            let originX = transform.originX;
            let originY = transform.originY;
            let zoomFactor = transform.zoomFactor * transform.resolution;
            stage.setTransform(originX, originY, zoomFactor, -zoomFactor);
            stage.update();
        }
    }, [stage, layers, transform, transform.originX, transform.originY, transform.zoomFactor, transform.resolution]);

    useEffect(() => {
        if (stage.canvas && stage.canvas.getContext /*('2d')*/) {
            stage.update();
        }
    }, [stage, layers, controls, hoveredShape, firstMeasuredShape, secondMeasuredShape])

    return (
        <Fragment>
            {children}
        </Fragment>
    )
}

export default StageComponent;
