import * as ActionTypes from "../actionTypes";

export const defaultAppState = {
    title: "Debug Viewer",
    version: process.env.REACT_APP_VERSION,
    build: "3.0.0",
    // bg: "#F1F1F1",
    widthOn: true,
    displayVertices: false,
    displayLabels: false,
    measurePointsActive: false,
    measureShapesActive: false,
    showAboutPopup: false,
    importDataToNewLayer: true,       // if false, import data to affected layer
    showSpinner: false,
    showLayersWidget: false,
    showDownloadPopup: false,
    showUploadPopup: false,
};

const app = (state = defaultAppState, action) => {
    switch (action.type) {
        case ActionTypes.STAGE_UNMOUNTED:
            return {
                ...state,
                stage: null,
                zoomFactor: undefined,
                originX: undefined,
                originY: undefined
            };

        case ActionTypes.TOGGLE_WIDTH_MODE_CLICKED:
            return {
                ...state,
                widthOn: !state.widthOn,
                displayVertices: state.widthOn ? state.displayVertices : false
            };

        case ActionTypes.TOGGLE_DISPLAY_VERTICES_CLICKED:
            if (state.displayVertices) {
                return {
                    ...state,
                    displayVertices: false
                };
            }
            else {
                return {
                    ...state,
                    widthOn: false,
                    displayVertices: true
                };
            }

        case ActionTypes.TOGGLE_DISPLAY_LABELS_CLICKED:
            return {
                ...state,
                displayLabels: !state.displayLabels
            };

        case ActionTypes.TOGGLE_ABOUT_POPUP_CLICKED:
            return {
                ...state,
                showAboutPopup: !state.showAboutPopup
            };

        case ActionTypes.PAN_BY_DRAG_BUTTON_CLICKED:
            return {
                ...state,
                measurePointsActive: false,
                measureShapesActive: false
            };

        case ActionTypes.MEASURE_POINTS_BUTTON_PRESSED:
            return {
                ...state,
                measurePointsActive: true,
                measureShapesActive: false
            };

        case ActionTypes.MEASURE_SHAPES_BUTTON_PRESSED:
            return {
                ...state,
                measurePointsActive: false,
                measureShapesActive: !state.measureShapesActive
            };

        case ActionTypes.TOGGLE_SHOW_LAYER_LIST_CLICKED:
            return {
                ...state,
                showLayersWidget: !state.showLayersWidget
            };

        case ActionTypes.TOGGLE_DOWNLOAD_POPUP_CLICKED:
            return {
                ...state,
                showDownloadPopup: !state.showDownloadPopup
            };
        case ActionTypes.TOGGLE_UPLOAD_POPUP_CLICKED:
            return {
                ...state,
                showUploadPopup: !state.showUploadPopup
            }
        default:
            return state;
    }
};

export default app;
