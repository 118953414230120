import * as ActionTypes from "../actionTypes";

const unitsList = [
    {
        name: "pixels",
        decimals: 0,
        divisor: 1
    },
    {
        name: "inch",
        decimals: 7,
        divisor: 10160000
    },
    {
        name: "mm",
        decimals: 6,
        divisor: 400000
    }];

export const initialUnitsState = unitsList[0];

const unitsReducer = (state, action) => {
    switch (action.type) {
        case ActionTypes.TOGGLE_UNITS_CLICKED:
            let curUnitsId = unitsList.findIndex(units => state.name === units.name);
            let newUnits = unitsList[(curUnitsId + 1) % 3];
            return {...state,
                name: newUnits.name,
                decimals: newUnits.decimals,
                divisor: newUnits.divisor
            };
        default:
            return state
    }
}

export default unitsReducer;
