import {Bitmap} from '@createjs/easeljs';
import {useEffect, useMemo} from "react";

const ImageComponent = ({stage, layer, model, displayed, zoomFactor, resolution}) => {

    // const handleMouseOver = useCallback(
    //     (event) => measureShapesDispatch(measureShapesActions.handleMouseRollOverShape(event.target.model)),
    //     [measureShapesDispatch]
    // );
    //
    // const handleMouseOut = useCallback(
    //     () => measureShapesDispatch(measureShapesActions.handleMouseRollOutShape()),
    //     [measureShapesDispatch]
    // );
    //
    // const handleClick = useCallback(
    //     (event) => measureShapesDispatch(measureShapesActions.handleClickOnShape(event.target.model, event.target.layer)),
    //     [measureShapesDispatch]
    // );

    const bitmap = useMemo( () => {
        if (stage) {
            const newBitmap = new Bitmap(model.geom.img);
            // bitmap.on("mouseover", handleMouseOver);
            // bitmap.on("mouseout", handleMouseOut);
            // bitmap.on("click", handleClick);
            stage.addChild(newBitmap);
            return newBitmap;
        }
    }, [stage, model])

    useEffect( () => {
        return () => {
            if (stage && bitmap) {
                // bitmap.off("mouseover", handleMouseOver );
                // bitmap.off("mouseout", handleMouseOut );
                // bitmap.off("click", handleClick );
                stage.removeChild(bitmap);
                bitmap.image = null // ?
            }
        }
    }, [stage, bitmap])

    // Draw image
    useEffect(() => {
        if (stage && bitmap) {
            let alpha = 1;
            bitmap.alpha = displayed ? alpha : 0.0;

            const box = model.geom.box;
            const width = box.xmax - box.xmin;

            let ratio = bitmap.image.naturalWidth/bitmap.image.naturalHeight;
            let scaleX = width/bitmap.image.naturalWidth;
            let scaleY = width/(bitmap.image.naturalHeight*ratio);
            let tx = box.center.x;
            let ty = box.center.y;

            bitmap.setTransform(tx, ty, scaleX, -scaleY);

            bitmap.regX = bitmap.image.naturalWidth/2;
            bitmap.regY = bitmap.image.naturalHeight/2;
        }
    }, [stage, bitmap, displayed, zoomFactor, resolution, layer, model])

    return null
};

export default ImageComponent;
