import * as ActionTypes from "../actionTypes";

export const defaultMouseState = {
    x: 0,
    y: 0,
    startX: undefined,
    startY: undefined,
    touchPoints: undefined,
    delta: 0,
    ratio: 1
};

const mouse = (state = defaultMouseState, action) => {
    switch (action.type) {
        case ActionTypes.MOUSE_MOVED_ON_STAGE:
            return {...state,
                x: action.x,
                y: action.y,
                delta: 0
            };

        case ActionTypes.MOUSE_DOWN_ON_STAGE:
            return {...state,
                startX: action.x,
                startY: action.y,
                delta: 0
            };

        case ActionTypes.MOUSE_UP_ON_STAGE:
            return {...state,
                startX: undefined,
                startY: undefined,
                delta: 0
            };

        case ActionTypes.MOUSE_WHEEL_MOVE_ON_STAGE:
            return {...state,
                startX: undefined,
                startY: undefined,
                delta: action.delta
            };

        case ActionTypes.PINCH_DOWN_ON_STAGE: {
            return {
                ...state,
                touchPoints: [
                    {x: action.x1, y: action.y1},
                    {x: action.x2, y: action.y2}
                ],
                ratio: 1.0
            }
        }

        case ActionTypes.PINCH_MOVED_ON_STAGE: {
            let dx = state.touchPoints[0].x - state.touchPoints[1].x;
            let dy = state.touchPoints[0].y - state.touchPoints[1].y;
            let distStart = Math.sqrt(dx*dx + dy*dy);

            dx = action.x2 - action.x1;
            dy = action.y2 - action.y1;
            let distCurrent = Math.sqrt(dx*dx + dy*dy);
            let ratio = distCurrent / distStart;

            return {
                ...state,
                touchPoints: [
                    {x: action.x1, y: action.y1},
                    {x: action.x2, y: action.y2}
                ],
                ratio: ratio
            }
        }

        case ActionTypes.PINCH_UP_ON_STAGE: {
            return {
                ...state,
                touchPoints: undefined,
                ratio: 1.0
            }
        }

        default:
            return state;
    }
};

export default mouse;
