import {useEffect, useImperativeHandle, useRef, useReducer, useState, forwardRef, useCallback} from 'react';
import styles from './EditorComponent.module.css';

import * as layerActions from "../../store/actions/layersActions";
import measureShapesReducer, {defaultMeasureShapesState} from "../../store/reducers/measureShapesReducer";

import DisplayCoordinatesTool from "../../tools/DisplayCoordinates/DisplayCoordinatesTool";
import StageComponent from "./StageComponent/StageComponent";
import useMouse from "../../hooks/useMouse";
import Layers from "../../models/layers";
import LayersComponent from "../graphics/layersComponent";
import LayersWidget from "../LayersWidget/LayersWidget";
import MeasureBetweenPoints from "../../tools/MeasureBetweenPoints/MeasureBetweenPoints";
import Stage from "../../models/stage";
import MeasureShapesTool from "../../tools/measureShapesTool";

const initialTransformState = {originX: null, originY: null, resolution: 0.0001, zoomFactor: 1.0}

const EditorComponent = (props, ref) => {
    const editorRef = useRef(null);
    const canvasElement = useRef(null);
    const [stage, setStage] = useState(null);
    const [transform, setTransform] = useState(initialTransformState);
    // const [layers, layersDispatch] = useReducer(layersReducer, [])
    const [measureShapesState, measureShapesDispatch] = useReducer(measureShapesReducer, defaultMeasureShapesState);
    const mouseState = useMouse(stage, measureShapesDispatch);

    useEffect( () => {
        if (canvasElement) {
            const newStage = new Stage(canvasElement.current);
            setStage(newStage);
        }
    },[canvasElement])

    useEffect( () => {
        if (canvasElement) {
            const canvas = canvasElement.current;
            const container = canvasElement.current.parentElement;
            canvas.style.position = "absolute";
            canvas.style.top = container.offsetTop + "px";
            canvas.style.left = container.clientLeft + "px";
            canvas.height = container.clientHeight;
            canvas.width = container.clientWidth;
        }
    }, [canvasElement]);

    useEffect( () => {
        const resizeCanvas = (event) => {
            const canvas = canvasElement.current;
            const container = canvasElement.current.parentElement;
            canvas.width = container.clientWidth;
            canvas.height = container.clientHeight;
            const newTransform = stage.resize();
            setTransform(newTransform);
        }

        window.addEventListener("resize", resizeCanvas);

        return () => {
            window.removeEventListener("resize", resizeCanvas)
        }
    }, [stage])

    useEffect( () => {
        if (props.onPasteDataFromBuffer) {
            document.addEventListener('paste', props.onPasteDataFromBuffer);
        }
        return () => {
            if (props.onPasteDataFromBuffer) {
                document.removeEventListener('paste', props.onPasteDataFromBuffer);
            }
        }
    }, [props.onPasteDataFromBuffer])

    useEffect( () => {
        for (const layer of props.layers) {
            if (layer.stage)       // only if layer.stage undefined, it is new layer, otherwise skip
                continue;
            layer.stage = stage;
            const homeTransform = stage.getInitialTransform(layer);
            setTransform(homeTransform);
        }
    }, [stage, props.layers])

    // Force update on any kind of layers changed
    // useEffect( () => {
    //     // const wrkLayer = Layers.getAffected(props.layers);
    //     if (stage) {
    //         // wrkLayer.stage = stage;
    //         const newTransform = stage.cloneTransform();
    //         setTransform(newTransform);
    //     }
    // }, [stage, props.layers])

    // Set transformation on pan by mouse
    useEffect( () => {
        if (stage && !props.measurePointsActive && mouseState.startX && mouseState.startY) {
            const dx = mouseState.x - mouseState.startX;
            const dy = mouseState.y - mouseState.startY;
            if (dx !== 0 && dy !== 0) {
                const newTransform = stage.panByMouseMove(dx, dy);
                setTransform(newTransform);
            }
        }
    }, [stage, mouseState, props.measurePointsActive])

    // Set transformation on zoom by mouse wheel
    useEffect( () => {
        if (stage && mouseState.delta !== 0) {
            let bIn = mouseState.delta > 0;
            const newTransform = stage.zoomByMouse(mouseState.x, mouseState.y, bIn, 1.2);
            setTransform(newTransform);
        }
    }, [stage, mouseState])

    // Set transformation on zoom by pinch
    useEffect( () => {
        if (stage && mouseState.touchPoints) {
            const newTransform = stage.zoomByPinchMove(mouseState.x1, mouseState.y1, mouseState.ratio);
            setTransform(newTransform);
        }
    }, [stage, mouseState]);

    const zoomHomeCallback = useCallback( () => {
        const wrkLayer = Layers.getAffected(props.layers);
        if (wrkLayer && stage) {
            const center = wrkLayer.center;
            const box = wrkLayer.box;
            if (isNaN(center.x) || isNaN(center.y)) return;
            stage.panToCoordinate(center.x, center.y);
            const newTransform = stage.zoomToLimits(box.xmax - box.xmin, box.ymax - box.ymin);
            setTransform(newTransform);
        }
    }, [stage, props.layers]);

    useEffect( () => {
        const handleKeyDown = (e) => {
            // let ctrl = e.ctrlKey;
            // if (e.target.id !== "mainCanvas")
            //     return;
            switch (e.code) {
                case ("KeyH" || "Home"):
                    zoomHomeCallback();
                    break;

                case ("Home"):
                    zoomHomeCallback();
                    break;

                case "KeyW":
                    // this.props.toggleWidthMode();     // toggle width On/Off in graphics model
                    break;

                case "KeyE":
                    // this.props.toggleDisplayVertices();  // toggle vertices On/Off
                    break;

                case "ArrowRight":
                    break;

                case "ArrowLeft":
                    break;
                case "ArrowUp":
                    break;
                case "ArrowDown":
                    break;
                default:
                    break;
            }
        };

        document.addEventListener('keydown', handleKeyDown);

        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        }
    }, [stage, props.layers, zoomHomeCallback]);

    useImperativeHandle(ref, () => ({
        zoomHome: zoomHomeCallback
    }), [zoomHomeCallback]);

    let measureBetweenShapes =
        props.measureShapesActive &&
        measureShapesState.firstMeasuredShape && measureShapesState.secondMeasuredShape &&
        measureShapesState.firstMeasuredLayer.displayed && measureShapesState.secondMeasuredLayer.displayed &&
        measureShapesState.distance && measureShapesState.shortestSegment;

    const hoveredShape = props.measureShapesActive ? measureShapesState.hoveredShape : null;
    const firstMeasuredShape = props.measureShapesActive ? measureShapesState.firstMeasuredShape : null;
    const secondMeasuredShape = props.measureShapesActive ? measureShapesState.secondMeasuredShape : null;

    return (
        <div className={styles.EditorComponent} ref={editorRef}>
            {props.showLayersWidget && <LayersWidget
                stage={stage}
                layers={props.layers}
                toggleDisplayLayer={(layer) => props.layersDispatch(layerActions.toggleDisplayLayer(layer))}
                toggleAffectedLayer={(event, layer) => props.layersDispatch(layerActions.toggleAffectedLayer(event, layer))}
                onEditLayerButtonClicked={(layer) => props.layersDispatch(layerActions.openLayerEditForm(layer))}
                onDeleteLayerButtonClicked={(layer) => props.layersDispatch(layerActions.deleteLayer(layer))}
                onSubmitLayerEditForm = {(newLayer) => props.layersDispatch(layerActions.updateLayer(newLayer))}
                onEscapeLayerEditForm = {() => props.layersDispatch(layerActions.closeEditLayerForm())}
                setAffectedNextLayer = {() => props.layersDispatch(layerActions.setAffectedNextLayer())}
                setAffectedPrevLayer = {() => props.layersDispatch(layerActions.setAffectedPrevLayer())}
            />}

            <canvas ref={canvasElement} width="100" height="100"></canvas>

            <DisplayCoordinatesTool
                stage={stage}
                unitsName={props.unitsName || "pixels"}
                divisor={props.divisor || 1.0}
                decimals={props.decimals || 0}
                coordX={mouseState.x}
                coordY={mouseState.y}
            />

            {stage && stage.canvas ?
                <StageComponent
                    stage={stage}
                    layers={props.layers}
                    transform={transform}
                    controls={props.appState}
                    hoveredShape={hoveredShape}
                    firstMeasuredShape={firstMeasuredShape}
                    secondMeasuredShape={secondMeasuredShape}
                >
                    <LayersComponent
                        stage={stage}
                        layers={props.layers}
                        widthOn={props.widthOn}
                        displayVertices={props.displayVertices}
                        displayLabels={props.displayLabels}
                        zoomFactor={transform.zoomFactor}
                        resolution={transform.resolution}
                        originX={transform.originX}
                        originY={transform.originY}
                        divisor={props.divisor || 1.0}
                        decimals={props.decimals || 0}
                        hoveredShape={hoveredShape}
                        firstMeasuredShape={firstMeasuredShape}
                        secondMeasuredShape={secondMeasuredShape}
                        measureShapesDispatch={measureShapesDispatch}
                    />
            </StageComponent> : null}

            {props.measurePointsActive && <MeasureBetweenPoints
                stage={stage}
                mouseState={mouseState}
                transform={transform}
                unitsName={props.unitsName || "pixels"}
                divisor={props.divisor || 1.0}
                decimals={props.decimals || 0}
            />}

            {measureBetweenShapes && <MeasureShapesTool
                stage={stage}
                transform={transform}
                firstMeasuredShape={measureShapesState.firstMeasuredShape}
                secondMeasuredShape={measureShapesState.secondMeasuredShape}
                firstMeasuredLayer={measureShapesState.firstMeasuredLayer}
                secondMeasuredLayer={measureShapesState.secondMeasuredLayer}
                distance={measureShapesState.distance}
                shortestSegment={measureShapesState.shortestSegment}
                divisor={props.divisor}
                decimals={props.decimals}
            />}

        </div>
    );
};

export default forwardRef(EditorComponent);
