import { Job } from '../models/job';
import {parseDebug} from './parseDebug';

export function parseTXT(filename, str) {
    let job = new Job();
    job.filename = filename;

    let shapesArray = parseDebug(str);

    if (shapesArray.length > 0) {
        for (let shape of shapesArray) {
            job.shapes.push(shape)
        }
    }

    return job
}