import {Job} from "../models/job";
import {point} from "@flatten-js/core";
import Model from "../models/model";
import {stringToJson} from "../models/utils";

const KEYPOINT_COLOR_VISIBLE = "#0f93ee"
const KEYPOINT_COLOR_INVISIBLE = "#fca708"

function getCoordinates2dAndVisibility(key_point) {
    // const pt = point(
    //     key_point.pixel_2d.y,
    //     1024 - key_point.pixel_2d.x
    // );
    const pt = point(key_point.pixel_2d.y, key_point.pixel_2d.x);
    const is_visible = key_point.hasOwnProperty("is_visible") ? key_point.is_visible === "true" : true;
    return {pt, is_visible}
}

function transformKeyPointToModel(key_point, label) {
    const {pt, is_visible} = getCoordinates2dAndVisibility(key_point)
    const fillColor = is_visible ? KEYPOINT_COLOR_VISIBLE : KEYPOINT_COLOR_INVISIBLE
    const style = {
        fill: fillColor,
        radius: 2.
    }
    const model = new Model(pt, style, label)
    return model
}

function parseJsonTree(key_points_json, models = []) {
    for(let key of Object.keys(key_points_json)) {
        if (key === "version") continue;
        if (key_points_json[key].hasOwnProperty("center_of_mass") &&
            key_points_json[key]["center_of_mass"].hasOwnProperty("pixel_2d")) {
            const key_point = key_points_json[key]["center_of_mass"]
            const label = key_points_json[key]["semantic_name"]
            const key_points_model = transformKeyPointToModel(key_point, label)
            models.push(key_points_model)
        }
        else {
            models = parseJsonTree(key_points_json[key], models)
        }
    }
    return models
}

function parseCenterOfGeometry(filename="", str) {
    let job = new Job();
    job.filename = filename;
    job.shapes = parseJsonTree(stringToJson(str))
    return job;
}

export default parseCenterOfGeometry
