import {useCallback, useEffect, useMemo} from 'react';
import {Shape} from '@createjs/easeljs';
import {graphics} from '../../models/graphics';
import * as measureShapesActions from "../../store/actions/measureShapesActions";
import Layers from "../../models/layers";

const ShapeComponent = ({stage, layer, model, displayed, widthOn, zoomFactor, resolution, selected,
                            hovered, measureShapesDispatch}) => {
    // const [shape, setShape] = useState(null);

    const handleMouseOver = useCallback(
        (event) => measureShapesDispatch(measureShapesActions.handleMouseRollOverShape(event.target.model)),
        [measureShapesDispatch]
    );

    const handleMouseOut = useCallback(
        () => measureShapesDispatch(measureShapesActions.handleMouseRollOutShape()),
        [measureShapesDispatch]
    );

    const handleClick = useCallback(
        (event) => measureShapesDispatch(measureShapesActions.handleClickOnShape(event.target.model, event.target.layer)),
        [measureShapesDispatch]
    );

    const shape = useMemo( () => {
        if (stage) {
            const newShape = new Shape();
            newShape.model = model;             // augment createjs shape structure
            // newShape.layer = layer;
            newShape.on("mouseover", handleMouseOver);
            newShape.on("mouseout", handleMouseOut);
            newShape.on("click", handleClick);
            stage.addChild(newShape);
            return newShape;
        }
    }, [stage, model, handleMouseOver, handleMouseOut, handleClick])

    // useEffect( () => {
    //     if (stage) {
    //         const newShape = new Shape();
    //         newShape.model = model;             // augment createjs shape structure
    //         newShape.on("mouseover", handleMouseOver );
    //         newShape.on("mouseout", handleMouseOut );
    //         newShape.on("click", handleClick );
    //         stage.addChild(newShape);
    //         setShape(newShape);
    //     }
    // }, [stage, model, handleMouseOver, handleMouseOut, handleClick])

    useEffect( () => {
        return () => {
            if (stage && shape) {
                shape.off("mouseover", handleMouseOver );
                shape.off("mouseout", handleMouseOut );
                shape.off("click", handleClick );
                stage.removeChild(shape);
                shape.graphics.clear();
            }
        }
    }, [stage, shape, handleMouseOver, handleMouseOut, handleClick])

    // Draw shape
    useEffect( () => {
        if (stage) {
            let color = (hovered || selected) ? "black" : layer.color;
            let alpha = (hovered || selected) ? 1.0 : 0.6;
            let strokeStyle = model.geom.aperture ? model.geom.aperture : undefined;
            let fill = widthOn ? layer.color : "white";
            let radius = 3.;

            if (model.style.hasOwnProperty("fill")) {
                fill = model.style.fill
            }
            if (model.style.hasOwnProperty("radius")) {
                radius = model.style.radius
            }

            shape.model = model;
            shape.layer = layer;

            if (shape.graphics.isEmpty()) {
                let geom = model.geom.clone();
                let matrix = Layers.getMatrix();
                if (geom.transform) {
                    geom = geom.transform(matrix)
                }
                shape.graphics = graphics(geom, {
                    strokeStyle: strokeStyle,
                    ignoreScale: true,
                    stroke: color,
                    fill: fill,
                    radius: radius / (zoomFactor * resolution)
                });
            } else {
                if (shape.graphics.stroke) shape.graphics.stroke.style = color;
                if (shape.graphics.fill) shape.graphics.fill.style = fill;
                if (shape.graphics.circle) shape.graphics.circle.radius =
                    radius / (zoomFactor * resolution);
            }
            shape.alpha = displayed ? alpha : 0.0;
        }

    }, [stage, shape, displayed, widthOn, zoomFactor, resolution, layer, model, hovered, selected, layer.color,
        handleMouseOver, handleMouseOut, handleClick])

    return null;
};

export default ShapeComponent;

