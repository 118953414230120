import * as ActionTypes from "../actionTypes";

export const toggleMeasureBetweenShapes = () => {
    return {
        type: ActionTypes.MEASURE_SHAPES_BUTTON_PRESSED
    }
};

export const handleMouseRollOverShape = (shape) => {
    return {
        type: ActionTypes.MOUSE_ROLL_OVER_SHAPE,
        shape
    }
};

export const handleMouseRollOutShape = () => {
    return {
        type: ActionTypes.MOUSE_ROLL_OUT_SHAPE
    }
};

export const handleClickOnShape = (shape, layer) => {
    return {
        type: ActionTypes.MOUSE_CLICKED_ON_SHAPE,
        shape,
        layer
    }
};

export const handleMouseDown = () => {
    return {
        type: ActionTypes.MOUSE_DOWN_ON_STAGE
    }
}

