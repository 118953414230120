import {useCallback, useEffect, useReducer, useState} from 'react';
import {Graphics, Shape} from "@createjs/easeljs";
import {format} from '../../models/utils';
import styles from './MeasureBetweenPoints.module.css';

const initialState = {
    points: new Array(2),
    started: false
}

const mouse = (state, action) => {
    const stage = action.stage || null;
    switch (action.type) {
        case "MOUSE_DOWN":
            if (state.started) {
                return initialState;
            }
            else {
                return {
                    points: [
                        {x: stage.C2W_X(action.x), y: stage.C2W_Y(action.y)},
                        {x: stage.C2W_X(action.x), y: stage.C2W_Y(action.y)}
                    ],
                    started: true
                }
            }

        case "MOUSE_MOVED":
            if (state.started) {
                return {
                    points: [
                        {x: state.points[0].x, y: state.points[0].y},
                        {x: stage.C2W_X(stage.mouseX), y: stage.C2W_Y(stage.mouseY)}
                    ],
                    started: true
                }
            }
            else {
                return state;
            }
        default:
            return state
    }
}
const MeasureBetweenPoints = ({stage, divisor, decimals}) => {
    const [rect, setRect] = useState(null);
    const [segment, setSegment] = useState(null);
    const [measurementState, dispatch] = useReducer(mouse, initialState);

    const draw = useCallback(() => {
        const measurePoints = measurementState.points;
        const point1 = {x: measurePoints[0].x, y: measurePoints[0].y}
        const point2 = {x: measurePoints[1].x, y: measurePoints[1].y}

        // Draw rectangle
        let pllX = Math.min(point1.x, point2.x);
        let pllY = Math.min(point1.y, point2.y);
        let width = Math.abs(point1.x - point2.x);
        let height = Math.abs(point1.y - point2.y);

        let graphicsRect = new Graphics();
        graphicsRect
            .setStrokeStyle(1,0,0,10,true)
            .beginStroke("#000000")
            .drawRect(pllX, pllY, width, height);
        rect.graphics = graphicsRect;

        let graphicsSegment = new Graphics();
        graphicsSegment
            .setStrokeStyle(1,1,0,10, true)
            .beginStroke("#000000")
            .moveTo(point1.x, point1.y)
            .lineTo(point2.x, point2.y)
            .endStroke();
        segment.graphics = graphicsSegment;

        rect.visible = true;
        segment.visible = true;
        stage.update();

    }, [stage, measurementState, rect, segment]);

    const erase = useCallback( () => {
        if (stage && rect && segment) {
            rect.visible = false;
            segment.visible = false;
            stage.update();
        }
    }, [stage, rect, segment])

    // Draw measurement on mouse move
    useEffect(() => {
        if (measurementState.started) {
            draw();
        }
        else {
            erase();
        }
    }, [stage, measurementState, draw, erase])

    useEffect(() => {
        const handleMouseDown = (event) =>
            dispatch({
                type: "MOUSE_DOWN",
                stage: stage,
                x: event.stageX,
                y: event.stageY
            })

        if (stage) {
            stage.addEventListener("stagemousedown", handleMouseDown);

            let rectShape = new Shape();
            stage.addChild(rectShape);
            setRect(rectShape);

            let segmentShape = new Shape();
            stage.addChild(segmentShape);
            setSegment(segmentShape);
        }
        return () => {
            if (stage) {
                stage.removeEventListener("stagemousedown", handleMouseDown);
            }
        }
    }, [stage]);

    useEffect( () => {
        dispatch({
            type: "MOUSE_MOVED",
            stage: stage,
        })
    }, [stage, stage.mouseX, stage.mouseY ])

    useEffect( () => {
        return () => {
            if (stage) {
                stage.removeChild(rect);
                stage.removeChild(segment);
            }
        }
    }, [stage, rect, segment])

    const measurement = (point1, point2, divisor, decimals) => {
        let dx = Math.abs(point1.x - point2.x);
        let dy = Math.abs(point1.y - point2.y);
        let dist = Math.sqrt(dx * dx + dy * dy);
        let message = `DX=${format(dx, divisor, decimals)}, DY=${format(dy, divisor, decimals)}, D=${format(dist, divisor, decimals)}`;
        return message;
    }

    if (measurementState.started) {
        const measurePoints = measurementState.points;
        const point1 = {x: measurePoints[0].x, y: measurePoints[0].y}
        const point2 = {x: measurePoints[1].x, y: measurePoints[1].y}
        const pllX = stage.W2C_X( Math.min(point1.x, point2.x) );
        const pllY = stage.W2C_Y( Math.min(point1.y, point2.y) ) + stage.canvas.offsetTop;
        const text = measurement(point1, point2, divisor, decimals);

        return (
            <div style={{position: "absolute", left: pllX, top: pllY}} className={styles.MeasureBetweenPoints}>
                <h5>{text}</h5>
            </div>
        )
    }
    else {
        return null;
    }
};

export default MeasureBetweenPoints;
